import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { Txt } from '@vst/beam';
import { ProductType } from '@mfe/shared/schema-types';

export const AddonsDisclosures = () => {
  const { availableAddons } = useSelector(selectAddOns);
  const { loading, productTypes } = availableAddons;

  if (loading) {
    return <AddonsDisclosuresLoading />;
  }

  if (!productTypes) {
    return null;
  }

  const productTypesWithDisclosure = productTypes?.filter(withDisclosureFilter);

  if (!productTypesWithDisclosure?.length) {
    return null;
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        {productTypesWithDisclosure?.map((productType, i) => (
          <ProductTypeDisclosure key={i} productType={productType} />
        ))}
      </div>
    </div>
  );
};

const AddonsDisclosuresLoading = () => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        {Array.from(new Array(3)).map((_, i) => {
          return (
            <Txt key={i} component="p" variant="smallRegular">
              <span className={styles['textLinePlaceholder']}></span>
              <span className={styles['textLinePlaceholder']}></span>
              <span className={styles['textLinePlaceholder']}></span>
            </Txt>
          );
        })}
      </div>
    </div>
  );
};

const ProductTypeDisclosure = ({
  productType,
}: {
  productType: ProductType;
}) => {
  const translation = productType.marketing_copy.translations?.find(
    (t) => t.language === 'en_US'
  );

  if (!translation) {
    return null;
  }

  const disclosureCharacteristic = translation.characteristics?.find(
    (characteristic) => characteristic.name === 'DISCLOSURE'
  );

  if (!disclosureCharacteristic) {
    return null;
  }

  return (
    <Txt component="p" variant="smallRegular">
      {disclosureCharacteristic.value}
    </Txt>
  );
};

const withDisclosureFilter = (productType: ProductType) => {
  const translation = productType.marketing_copy.translations?.find(
    (t) => t.language === 'en_US'
  );

  if (!translation) {
    return false;
  }

  return !!translation.characteristics?.find(
    (characteristic) => characteristic.name === 'DISCLOSURE'
  );
};

export default AddonsDisclosures;
