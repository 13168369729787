import { ApolloQueryResult, gql } from '@apollo/client';
import { takeLatest, call, put, all, select, take } from 'redux-saga/effects';
import {
  Query,
  Locale,
  TokenType,
  GetBepInputsPayloadProduct,
  ProductInstanceStatus,
} from '@mfe/shared/schema-types';
import {
  selectUser,
  setUser,
  setAuth,
  fetchUser as fetchUserAction,
  setAuthUserError,
} from '.';
import { sortProductInstancesByStatus } from '../../serverUtils';
import { selectLocale } from '../locale';
import { setAddress } from '../userInfo';
import {
  setAddOns,
  setCurrentAddons,
  setAddOnsProductInstanceIds,
} from '../addOns/addOnsSlice';

import {
  graphqlQueryWithErrors,
  FetchWithErrorsQuery,
} from '@mfe/shared/redux/graphql';
import { getWorkOrders } from '../workOrders';
import { getHasPendingTransition } from '../changePlan';

export const GET_BEP_INPUTS = gql`
  query getBEPInputs($refetchData: Boolean) {
    getBEPInputs(refetchData: $refetchData) {
      partyId
      relnId
      accountNumber
      nationalId
      products {
        kind
        isACPSuspended
        isSoftSuspended
        hasCaf
        productInstanceId
        productInstanceStatus
        productTypeId
      }
      address {
        addressLines
        municipality
        region
        postalCode
        countryCode
      }
      currentAddons {
        kind
        productInstanceId
        productTypeId
        state
        characteristics {
          name
          value
        }
      }
      addons {
        hasEasyCare
        hasShield
        hasVoice
        hasStaticIP
        hasOfficeHours
      }
      addOnsProductInstanceIds
    }
  }
`;

export const parseUser = (
  data: Partial<ApolloQueryResult<Query>['data']>,
  planChanged?: boolean
) => {
  const bepInputs = data.getBEPInputs;

  const productSortedByStatus = sortProductInstancesByStatus(
    (bepInputs?.products as GetBepInputsPayloadProduct[]) ?? [],
    planChanged
  );

  return {
    accountNumber: bepInputs?.accountNumber ?? '',
    productInstanceId: productSortedByStatus?.[0]?.productInstanceId ?? null,
    productTypeId: productSortedByStatus?.[0]?.productTypeId ?? null,
    productKind: productSortedByStatus?.[0]?.kind ?? null,
    productInstanceStatus:
      productSortedByStatus?.[0]?.productInstanceStatus ??
      ProductInstanceStatus.Other,
    partyId: bepInputs?.partyId ?? '',
    relnId: bepInputs?.relnId ?? '',
    serviceAddress: bepInputs?.address,
    isACPSuspended: !!bepInputs?.products?.find(
      (productInstance) => productInstance?.isACPSuspended
    ),
    isSoftSuspended: !!bepInputs?.products?.find(
      (productInstance) => productInstance?.isSoftSuspended
    ),
    hasCaf: !!bepInputs?.products?.find(
      (productInstance) => productInstance?.hasCaf
    ),
    hasErrorProductInstance: !!bepInputs?.products?.find(
      (productInstance) =>
        productInstance?.productInstanceStatus ===
          ProductInstanceStatus.Error ?? null
    ),
  };
};

interface FetchUserInputType {
  username?: string;
  partyId?: string;
  locale?: Locale;
}

export function* fetchUser() {
  const {
    user: {
      auth: {
        username,
        tokenInfo: { type },
      },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);
  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const input: FetchUserInputType =
    type === TokenType.Salesforce ? { partyId: username } : { username };
  if (userLocale) input.locale = userLocale;

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_BEP_INPUTS,
    variables: { refetchData: true },
    fetchPolicy: 'network-only',
  });
  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setAuthUserError(runtimeError ?? errors));
    return;
  }

  if (data?.getBEPInputs) {
    const {
      accountNumber,
      productInstanceId,
      productTypeId,
      productInstanceStatus,
      productKind,
      partyId,
      relnId,
      serviceAddress,
      isACPSuspended,
      isSoftSuspended,
      hasCaf,
      hasErrorProductInstance,
    } = parseUser(data);

    yield put(
      setUser({
        accountNumber,
        productInstanceId,
        productTypeId,
        productInstanceStatus,
        productKind,
        partyId,
        relnId,
        isACPSuspended,
        isSoftSuspended,
        hasCaf,
        hasErrorProductInstance,
      })
    );

    yield put(getHasPendingTransition());

    yield put(
      setAddOns({
        hasEasyCare: data.getBEPInputs?.addons?.hasEasyCare,
        hasShield: data.getBEPInputs?.addons?.hasShield,
        hasVoice: data.getBEPInputs?.addons?.hasVoice,
        hasStaticIP: data.getBEPInputs?.addons?.hasStaticIP,
        hasOfficeHours: data.getBEPInputs?.addons?.hasOfficeHours,
      })
    );
    yield put(setAddress({ service: serviceAddress }));

    yield put(
      setAddOnsProductInstanceIds(
        data.getBEPInputs?.addOnsProductInstanceIds ?? []
      )
    );

    yield put(setCurrentAddons(data.getBEPInputs?.currentAddons ?? []));

    yield put(getWorkOrders());
  }
}

export function* waitForUserData() {
  const { loading } = yield select(selectUser);

  if (!loading) {
    yield take(setUser.type);
  }
}

export function* watchUser() {
  yield all([
    takeLatest(setAuth.type, fetchUser),
    takeLatest(fetchUserAction.type, fetchUser),
  ]);
}
