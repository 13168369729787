import { ComponentProps, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonLink, Accordion, AccordionGroup, Badge, Txt } from '@vst/beam';
import { AddOnCard, AddonCardSmall } from '@mfe/shared/components';
import { Storage } from '@mfe/shared/util';
import { Addon } from '@mfe/shared/schema-types';
import useNavigate from '@mfe/services/navigation';
import {
  getAddOnsPricesAndDiscounts,
  selectAddOns,
} from '@mfe/to-be-migrated/redux/addOns';
import styles from './index.module.scss';

const AddonsFooter = () => {
  // const { currentAddons } = useSelector(selectAddOns);
  const { t } = useTranslation('ShopAddons');
  const { goTo } = useNavigate();

  // currentAddons is populated in userSagas from getBEPInputs
  // but StreamON is not in the response
  // so we use getPricesAndDiscounts
  // if (!Array.isArray(currentAddons) || !currentAddons.length) {
  //   return null;
  // }

  const dispatch = useDispatch();
  const { addOnsPricesAndDiscounts } = useSelector(selectAddOns);

  // dispatch getAddOnsPricesAndDiscounts to fetch current addons details (including streamon)
  useEffect(() => {
    dispatch(getAddOnsPricesAndDiscounts);
  }, [dispatch]);

  if (!addOnsPricesAndDiscounts.length) {
    return null;
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        <AccordionGroup>
          <Accordion title={<AddonsFooterTitle />}>
            <div className={styles['addons-wrapper']}>
              <div className={styles['addons']}>
                {addOnsPricesAndDiscounts.map((addon, index) => {
                  return (
                    <div key={index} className={styles['addons__item']}>
                      <AddOnCard
                        addOnOffer={addon}
                        showPricesAndDiscounts={false}
                      />
                      {/* @todo refactor and replace w/ better component */}
                      {/* <AddonItem addon={addon} /> */}
                    </div>
                  );
                })}
              </div>
              <div className={styles['manage-addons-link']}>
                <ButtonLink
                  data-cy="manage-my-addons-link"
                  linkSize="medium"
                  onClick={(e) => {
                    e.preventDefault();
                    Storage.setItem('showInternetPlan', true);
                    goTo('Profile');
                  }}
                  arrowPos={'forward'}
                  href="#"
                  variant={'primary'}
                  label={t('myAddons.manageAddonsButtonLabel')}
                />
              </div>
            </div>
          </Accordion>
        </AccordionGroup>
      </div>
    </div>
  );
};

type AddonItemProps = {
  addon: Addon;
};

const AddonItem: React.FC<AddonItemProps> = (props) => {
  const { addon } = props;
  const title = addon.characteristics.find(
    (characteristic) => characteristic.name === 'MARKETING_COPY_OFFER_NAME'
  )?.value;
  const description = addon.characteristics.find(
    (characteristic) =>
      characteristic.name === 'MARKETING_COPY_OFFER_DESCRIPTION'
  )?.value;

  const badge: ComponentProps<typeof Badge> = {
    label: 'Subscribed',
    state: 'positive',
    showIcon: false,
  };

  return (
    <AddonCardSmall
      badge={badge}
      title={title}
      description={description}
      kind={addon.kind}
    />
  );
};

const AddonsFooterTitle = () => {
  const { t } = useTranslation('ShopAddons');
  const title = t('myAddons.title');

  return <Txt variant="labelLarge">{title}</Txt>;
};

export default AddonsFooter;
