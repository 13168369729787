import { Translation, UiBehaviors } from '../OFM/schemaTypes';
import { Characteristic } from '@mfe/shared/schema-types';

export type GetSubscriptionInput = {
  filter: string;
  include_historic_root_products: boolean;
};

export type GetSubscriptionPayload = {
  items: [Item];
};

type Item = {
  current_root_product: Subscription;
  pending_root_products: [Subscription];
  historic_root_products: [Subscription];
};

export type Subscription = {
  marketing_copy: MarketingCopy;
  product_id: string;
  kind: string;
};

export type MarketingCopy = {
  translations: [Translation];
  ui_behaviors: UiBehaviors;
};

export type GetPortfolioPayload = {
  portfolioItems: [PortfolioItem];
  portfolioRelationships: [PortfolioRelationship];
};

// Should we use PSM schema types for this?
export type PortfolioItem = {
  productInstanceId: string;
  productTypeId: string;
  isRoot: boolean;
  locationIds: [string];
  state: string;
  kind: string;
  characteristics: [Characteristic];
};

export type PortfolioRelationship = {
  source: RelationShipItem;
  destination: RelationShipItem;
};

export type RelationShipItem = {
  id: string;
  name: string;
};

export type VerifyAndSaveACPResponse = {
  name?: string;
  message: string;
  error_type?: string;
  corrective_action?: string;
  trace_id?: string;
};

export type QueryParams = {
  application_id: string;
  customer_party_id: string;
  state_code: string;
  date_of_birth: string;
  has_tribal_benefit: boolean;
  transaction_type: string;
  first_name: string;
  last_name: string;
};

export type ProductType = {
  characteristics: Characteristic[];
  dealer_comp: DealerComp;
  description: string;
  discounts: Discounts;
  extension_types: string[];
  id: string;
  kind: string;
  marketing_copy: MarketingCopy;
  max_occurrence: number;
  min_occurrence: number;
  name: string;
  offer_id: string;
  prices: Price[];
  package_types: PackageType[];
};

export type GetProductInput = {
  product_instance_id: string;
  sales_agreement_id: string;
};

export type GetAddonsInput = {
  sales_agreement_id: string;
  root_product_instance_id: string;
  address_lines: Array<string>;
  municipality: string;
  region: string;
  country_code: string;
  postal_code: string;
  latitude: number;
  longitude: number;
};

export type PackageType = {
  characteristics: Characteristic[];
  description: string;
  id: string;
  kind: string;
  marketing_copy: MarketingCopy;
  max_selections: number;
  min_selections: number;
  max_occurrence: number;
  min_occurrence: number;
  name: string;
  package_types: string[];
  product_types: string[];
};

export type Price = {
  amount: Amount;
  description: string;
  kind: string;
  name: string;
};

export type DealerComp = {
  coop: string;
  comp: string;
  bonus: string;
};

export type Discounts = {
  itemized_discounts: Discount[];
  total_discounts: Discount;
};

export type Discount = {
  name: string;
  amount: Amount;
  duration: number;
};

export type Amount = {
  value: number;
  currency_code: string;
};

export type GetImpairmentsBySubscriptionPayload = {
  impairments_by_subscription: ImpairmentsBySubscription[];
};

export type ImpairmentsBySubscription = {
  idu_product_instance_id: string;
  impairments: Impairment[];
  location_id: string;
  mac_address: string;
  root_product_instance_id: string;
};

export type Impairment = {
  id: string;
  description: string;
  planned: string;
  start_time: string;
  end_time: string;
  status: string;
  causes: string[];
};

export type GetCurrentCustomerRelationshipsInput = {
  productInstanceId: string;
};

export enum AgreementType {
  SALES = 'SALES',
  FULFILLMENT = 'FULFILLMENT',
  WHOLESALE = 'WHOLESALE',
}

export type Agreement = {
  id: string;
  agreement_type: AgreementType;
};

export type GetCurrentCustomerRelationshipsResponse = {
  agreements: Array<Agreement>;
};

export type SubmitOrderInput = {
  order_id?: string;
  customer_party_id?: string;
  customer_relationship_id?: string;
  sales_agreement_id: string;
  order_lines: OrderLineInput[];
};

type OrderLineInput = {
  shopping_cart_id: string;
  order_line_id?: string;
  order_line_type?: string;
  billing_account_id: string;
  service_location: Location;
  fulfillment_agreement_id: string;
  tax_jurisdiction?: TaxJurisdictionInput;
  work_order_information?: WorkOrderInformation;
};

type TaxJurisdictionInput = {
  tax_code_type: string;
  tax_code_value: string;
};

type Location = {
  coordinates: Coordinates;
  address: Address;
};

type Coordinates = {
  latitude: number;
  longitude: number;
};

export type Address = {
  region: string;
  country_code: string;
  country_name?: string; // Marked as deprecated in PAL docs
  address_lines: string[];
  postal_code: string;
  municipality: string;
};

type WorkOrderInformation = {
  appointment: Appointment;
};

type Appointment = {
  start_time: string;
  end_time: string;
};

export type SubmitOrderPayload = {
  execution_id: string;
  order_id: string;
  state: OrderState;
  product_instance_ids: string[];
  product_instance_summary: ProductInstanceSummary;
  service_location_id: string;
  attributes: Attribute[];
  order_lines: OrderLine[];
};

type OrderLine = {
  order_line_id: string;
  order_line_type: string;
  billing_account_id: string;
  service_location: string;
  shopping_cart_id: string;
  fulfillment_agreement_id: string;
  work_order_information?: WorkOrderInformation;
  state?: OrderState;
  product_instance_id?: string;
  service_location_id?: string;
};

type Attribute = {
  name: string;
  value: string;
};

type ProductInstanceSummary = {
  product_instance_id: string;
  candidate_id: string;
};

enum OrderState {
  CANCELED,
  CANCELING,
  CANCELING_ERROR,
  CLOSED,
  INITIALIZING,
  INITIALIZED,
  INITIALIZED_ERROR,
  INITIALIZING_ERROR,
  OPEN,
  OPEN_ERROR,
  PROCESSED,
  PROCESSED_ERROR,
  REJECTED,
  SCHEDULED,
}

export type EvaluateAddressInput = Address;

type AddressResponse = {
  location: {
    address: Address;
    coordinates: {
      longitude: number;
      latitude: number;
    };
  };
  accuracy_level: string;
  process_status: ScrubAddressProcessStatus;
  recommendation: ScrubAddressRecommendation;
};

export enum ScrubAddressProcessStatus {
  Verified = 'V',
  Corrected = 'C',
  Incorrect = 'I',
}

export enum ScrubAddressRecommendation {
  NEEDS_VERIFICATION = 'NEEDS_VERIFICATION',
  RECOMMEND = 'RECOMMEND',
  REJECT = 'REJECT',
}

export type EvaluateAddressPayload = {
  addresses: AddressResponse[];
};

export type ResendBoletoInput = {
  billing_account_number: string;
  customer_party_id: string;
  invoice_number: string;
  order_id: string;
  is_notification_required: boolean;
};

export type ResendBoletoPayload = {
  payments?: Payment[];
  customer?: Customer;
};

type Payment = {
  amount_due?: Money | null | string;
  boleto_expired?: boolean | null;
  invoice_number?: string | null;
  invoice_sequence_number?: string | null;
  notification_status?: string | null;
  payment_amount?: Money | null;
  due_date?: string | null;
  payment_date?: string | null;
  payment_status?: string | null;
  payment_type?: string | null;
  payment_url?: string | null;
  pix_expired?: boolean | null;
  pix_payment_url?: string | null;
  pix_qr_code?: string | null;
};

type Customer = {
  customer_party_id?: string | null;
  customer_relationship_id?: string | null;
  billing_address?: BillingAddress;
};

type Money = {
  currency?: Currency;
  value?: number;
};

type Currency = {
  name?: string | null;
  alphabetic_code?: string | null;
  major_unit_symbol?: string | null;
  minor_units?: string | null;
};

type BillingAddress = {
  address_lines?: string[] | null;
  country_code?: string | null;
  postal_code?: string | null;
  region?: string | null;
  municipality?: string | null;
};
