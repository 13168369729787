import { FetchWithErrorsQuery } from '@mfe/shared/redux/graphql';
import { Addon, ProductType } from '@mfe/shared/schema-types';
import { createSlice } from '@reduxjs/toolkit';

export enum AddOnType {
  Voice = 'Voice',
  EasyCare = 'EasyCare',
  Shield = 'Shield',
  StaticIP = 'StaticIP',
}

export interface AddOnsState {
  loading: boolean;
  error: FetchWithErrorsQuery['errors'] | null;
  addOns: {
    hasEasyCare?: boolean;
    hasVoice?: boolean;
    hasShield?: boolean;
    hasStaticIP?: boolean;
    hasOfficeHours?: boolean;
  };
  addOnsProductInstanceIds: string[];
  addOnsPricesAndDiscounts: [];
  availableAddons: {
    loading: boolean;
    productTypes: Array<ProductType> | null;
  };
  currentShopAddonId: string | null;
  currentAddons: Array<Addon> | null;
}

export const initialAddOnsState: AddOnsState = {
  loading: true,
  error: null,
  addOns: {},
  addOnsProductInstanceIds: [],
  addOnsPricesAndDiscounts: [],
  availableAddons: {
    loading: true,
    productTypes: null,
  },
  currentShopAddonId: null,
  currentAddons: null,
};

export const addOnsSlice = createSlice({
  name: 'addOns',
  initialState: initialAddOnsState,
  reducers: {
    fetchAddOns: (state) => {
      state.loading = true;
    },
    fetchAvailableAddOns: (state) => {
      state.availableAddons = {
        ...state.availableAddons,
        loading: true,
      };
    },
    setAvailableAddOns: (
      state,
      { payload }: { payload: Array<ProductType> }
    ) => {
      state.availableAddons = {
        loading: false,
        productTypes: payload,
      };
    },
    setAddOns: (
      state,
      { payload }: { payload: Partial<AddOnsState['addOns']> }
    ) => {
      state.loading = false;
      state.addOns = { ...state.addOns, ...payload };
    },
    setAddOnsProductInstanceIds: (
      state,
      { payload }: { payload: string[] | [] }
    ) => {
      state.addOnsProductInstanceIds.push(...payload);
    },
    getAddOnsPricesAndDiscounts: (state) => {
      state.loading = true;
    },
    setAddOnsPricesAndDiscounts: (state, { payload }) => {
      state.loading = false;
      state.addOnsPricesAndDiscounts = payload;
    },
    setCurrentShopAddonId: (state, { payload }: { payload: string | null }) => {
      state.currentShopAddonId = payload;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    resetAddOns: () => initialAddOnsState,
    setCurrentAddons: (
      state,
      { payload }: { payload: Array<Addon> | null }
    ) => {
      state.currentAddons = payload;
    },
  },
});

export const {
  fetchAddOns,
  setAddOns,
  resetAddOns,
  setAddOnsProductInstanceIds,
  getAddOnsPricesAndDiscounts,
  setError,
  setAddOnsPricesAndDiscounts,
  fetchAvailableAddOns,
  setAvailableAddOns,
  setCurrentShopAddonId,
  setCurrentAddons,
} = addOnsSlice.actions;

export const selectAddOns = (state: { addOns: AddOnsState }) => state.addOns;
export const selectCurrentShopAddon = (state: { addOns: AddOnsState }) => {
  const { currentShopAddonId } = state.addOns;

  const currentShopAddon = state.addOns.availableAddons.productTypes?.find(
    (addon) => addon.id === currentShopAddonId
  );

  if (!currentShopAddon) {
    return null;
  }

  return currentShopAddon;
};
