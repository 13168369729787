import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { OneStepCheckout } from '@mfe/features/add-ons';
import { selectConfig } from '@mfe/shared/redux/config';

const CheckoutPage: React.FC = () => {
  const history = useHistory();
  const { showAddonsShop } = useSelector(selectConfig);

  useEffect(() => {
    if (!showAddonsShop) {
      history.replace('/');
    }
  }, [history, showAddonsShop]);

  if (!showAddonsShop) {
    return <></>;
  }

  return <OneStepCheckout />;
};

export default CheckoutPage;
