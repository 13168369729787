import { Badge, Icon, SectionAlert, Surface, Txt, colors } from '@vst/beam';
import styles from './styles.module.scss';

import { Offer } from '@vst/beam-icons/icons';
import { Trans, useTranslation } from 'react-i18next';

import { AddOnsPricesAndDiscounts } from '@mfe/shared/schema-types';
import { format } from 'date-fns';
import { getProps } from './utils';

type AddOnCardProps = {
  addOnOffer: AddOnsPricesAndDiscounts;
  showPricesAndDiscounts?: false;
};

export const AddOnCard: React.FC<AddOnCardProps> = (props) => {
  const { addOnOffer, showPricesAndDiscounts = true } = props;
  const { t } = useTranslation('Profile');

  const addonProps = getProps(addOnOffer.addOnName as string, t);

  const discountEndDate = new Date(addOnOffer.discountEndDate as string);

  const formattedDate = format(discountEndDate, 'MMMM dd, yyyy');

  const isProductFree = Number(addOnOffer.addOnPrice) === 0;

  return (
    <div className={styles['add-on-card-container']}>
      <Surface
        style={{ background: addonProps?.iconBackground }}
        className={styles['icon-container']}
      >
        {addonProps?.iconName && (
          <Icon
            size={'24'}
            icon={addonProps?.iconName}
            color={addonProps.iconColor}
          />
        )}
      </Surface>
      <div className={styles['add-on-content']}>
        <div className={styles['title-and-badge']}>
          <Txt variant="bodyLargeBold">{addOnOffer.addOnName}</Txt>
          <Badge state="positive" label="Subscribed" showIcon={false} />
        </div>
        <Txt variant="smallRegular" color="regular">
          {addonProps?.description}
        </Txt>

        {showPricesAndDiscounts &&
          (addOnOffer.priceWithDiscount ? (
            <DiscountedPrices
              addOnPrice={addOnOffer.addOnPrice as string}
              priceWithDiscount={addOnOffer.priceWithDiscount}
            />
          ) : (
            <Txt
              variant="bodySmallBold"
              color="subtle"
              style={
                isProductFree ? { color: colors['green'][700] } : undefined
              }
            >
              <Trans
                ns="Profile"
                i18nKey={isProductFree ? 'addOns.freePrice' : 'addOns.price'}
                values={{
                  addOnPrice: addOnOffer.addOnPrice,
                }}
              />
            </Txt>
          ))}

        {showPricesAndDiscounts && addOnOffer.discountName && (
          <SectionAlert variant="success" icon={Offer}>
            <Txt variant="smallRegular" color="regular">
              <Trans
                ns="Profile"
                i18nKey="addOns.offerAlert"
                values={{
                  discountName: addOnOffer.discountName,
                  discountEndDate: formattedDate,
                  addOnPrice: addOnOffer.addOnPrice,
                }}
              />
            </Txt>
          </SectionAlert>
        )}
      </div>
    </div>
  );
};

type DiscountedPricesProps = {
  addOnPrice: string;
  priceWithDiscount: string;
};

const DiscountedPrices = ({
  addOnPrice,
  priceWithDiscount,
}: DiscountedPricesProps) => {
  const { t } = useTranslation('Profile');

  return Number(priceWithDiscount) > 0 ? (
    <div>
      <Txt variant="bodySmallBold" color="subtle" component="span" mr="4px">
        <Trans
          ns="Profile"
          i18nKey={'addOns.priceWithDiscount'}
          values={{
            discountPrice: priceWithDiscount,
          }}
        />
      </Txt>
      <Txt variant="bodySmallRegular" color="subtle" component="span">
        <s>
          <Trans
            ns="Profile"
            i18nKey={'addOns.price'}
            values={{
              addOnPrice: addOnPrice,
            }}
          />
        </s>
      </Txt>
    </div>
  ) : (
    <div>
      <Txt
        variant="bodySmallBold"
        color="subtle"
        component="span"
        mr="4px"
        style={{ color: colors['green'][700] }}
      >
        {t('addOns.freePrice')}
      </Txt>
      <Txt variant="linkBodySmall" component="span">
        <s>
          <Trans
            ns="Profile"
            i18nKey={'addOns.price'}
            values={{
              addOnPrice,
            }}
          />
        </s>
      </Txt>
    </div>
  );
};
