import { all, call, select, takeLatest, put, take } from 'redux-saga/effects';
import { gql } from '@apollo/client';

import {
  AddOnsState,
  selectAddOns,
  setAddOnsPricesAndDiscounts,
  setAddOnsProductInstanceIds,
  setError,
  fetchAddOns,
  setAvailableAddOns,
} from './addOnsSlice';

import {
  FetchWithErrorsQuery,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';

export const GET_ADD_ONS_PRICES_AND_DISCOUNTS = gql`
  query getAddOnsPricesAndDiscounts($addOnsProductInstanceIds: [String]) {
    getAddOnsPricesAndDiscounts(
      addOnsProductInstanceIds: $addOnsProductInstanceIds
    ) {
      addOnName
      addOnPrice
      discountName
      discountEndDate
      discountAmount
      priceWithDiscount
    }
  }
`;

export const GET_LOCATION_PERSONALIZED_ADDONS = gql`
  query getLocationPersonalizedAddons {
    getLocationPersonalizedAddons {
      id
      kind
      name
      description
      characteristics {
        name
        value
      }
      discounts {
        total_discounts {
          name
          duration
          amount {
            value
            currency {
              minor_units
              numeric_code
              major_unit_symbol
              alphabetic_code
            }
          }
        }
        itemized_discounts {
          name
          duration
          amount {
            value
            currency {
              minor_units
              numeric_code
              major_unit_symbol
              alphabetic_code
            }
          }
        }
      }
      offer_id
      marketing_copy {
        ui_behaviors {
          characteristics {
            name
            value
          }
        }
        translations {
          language
          characteristics {
            name
            value
          }
        }
      }
      prices {
        amount {
          value
        }
      }
    }
  }
`;

export function* waitForAddOnsProductInstanceIds() {
  const addons: AddOnsState = yield select(selectAddOns);

  if (addons.loading) {
    yield take(setAddOnsProductInstanceIds.type);
  }
}

export function* fetchPersonalizedAddons() {
  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_LOCATION_PERSONALIZED_ADDONS,
    variables: {},
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  const addons = data?.getLocationPersonalizedAddons;

  if (addons) {
    yield put(setAvailableAddOns(addons));
  }
}

export function* fetchAddOnsPricesAndDiscounts(payload: unknown) {
  yield put(fetchAddOns);

  const { addOnsProductInstanceIds } = yield select(selectAddOns);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_ADD_ONS_PRICES_AND_DISCOUNTS,
    variables: { addOnsProductInstanceIds },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.getAddOnsPricesAndDiscounts) {
    yield put(
      setAddOnsPricesAndDiscounts(data.getAddOnsPricesAndDiscounts ?? [])
    );
  }
}

export function* watchAddOns() {
  yield all([
    takeLatest(setAddOnsProductInstanceIds.type, fetchAddOnsPricesAndDiscounts),
    takeLatest(setAddOnsProductInstanceIds.type, fetchPersonalizedAddons),
  ]);
}
