import { takeLatest, call, put, select, all, spawn } from 'redux-saga/effects';
import {
  GET_USER_ELIGIBILITY,
  GET_ALL_DETAILS,
  GET_INVOICE_HISTORY,
  GET_SCRUBBED_ADDRESS,
  SUBMIT_FULL_ORDER,
  DEACTIVATE_STREAM_ON,
} from './requests';
import {
  Query,
  SubmitFullOrderInput,
  Address,
  Mutation,
  PhoneType,
  ContactMethodLabels,
  GetScrubbedAddressPayload,
} from '@mfe/shared/schema-types';
import {
  setConfirmModal,
  setEditAddress,
  setInvoiceHistory,
  setIsEdittingAddress,
  setIsLoading,
  setIsOrderSubmitting,
  setIsScrubbingLoading,
  setOfferDetails,
  setOrderAttempts,
  addOrderAttempts,
  setOrderFail,
  setScrubLocation,
  setShippingAddress,
  setUserDetails,
  setCurrentPage,
  setUserEligibility,
  selectStreamOnPromo,
  setInvoiceHistoryLoading,
  setOfferDetailsLoading,
  getInvoiceHistory as getInvoiceHistoryAction,
  getAllDetails as getAllDetailsAction,
  submitFullOrder as submitFullOrderAction,
  scrubAddress as scrubAddressAction,
  deactivateStreamOn as deactivateStreamOnAction,
  setDeactivateStreamOn,
  setOrderSucceeded,
} from './streamOnPromoSlice';

import { Pages, resetOrderAttempts } from './types';
import { graphqlQuery, graphqlMutation } from '@mfe/shared/redux/graphql';
import {
  setOverviewPageStatistics,
  setProfilePageStatistics,
  setUsagePageStatistics,
} from '../utils';
import { ConfigState, selectConfig } from '@mfe/shared/redux/config';
import { waitForToken } from '../utils/utilsSagas';
import { selectUserInfo, refetchUserInfo } from '../userInfo';
import {
  selectAddOns,
  fetchAddOns,
  setAddOnsProductInstanceIds,
} from '../addOns';

const areAddressesEqual = (
  addressA: GetScrubbedAddressPayload | undefined | null,
  addressB: Address | undefined
): boolean => {
  if (
    (!addressA && addressB !== undefined) ||
    (addressB === undefined && addressA !== undefined)
  ) {
    return false;
  }
  if (!addressA && addressB === undefined) {
    return true;
  }

  return (
    addressA?.addressLines[0] === addressB?.addressLines[0] &&
    addressA?.addressLines[1] === addressB?.addressLines[1] &&
    addressA?.countryCode === addressB?.countryCode &&
    addressA?.municipality == addressB?.municipality &&
    addressA?.postalCode === addressB?.postalCode &&
    addressA?.region === addressB?.region
  );
};

const sanitizeAddressLines = (address: Address) => {
  const { addressLines } = address;
  if (addressLines[1] === null || addressLines[1] === undefined)
    return { ...address, addressLines: [addressLines[0], ''] };
  return address;
};

function* checkUserEligibility() {
  yield put(setIsLoading(true));

  // Make sure token is set before proceeding
  yield call(waitForToken);

  const getUserEligibilityData: Query = yield call(graphqlQuery, {
    query: GET_USER_ELIGIBILITY,
  });

  const isEligible =
    getUserEligibilityData?.checkUserEligibility?.isEligible ?? false;

  const hasStreamOnActive =
    getUserEligibilityData?.checkUserEligibility?.hasStreamOnActive ?? false;

  const streamOnProductInstanceId =
    getUserEligibilityData?.checkUserEligibility
      ?.viasatStreamProductInstanceId ?? null;

  const { addOnsProductInstanceIds } = yield select(selectAddOns);

  const isAlreadyAdded = addOnsProductInstanceIds.includes(
    streamOnProductInstanceId
  );

  if (hasStreamOnActive && streamOnProductInstanceId && !isAlreadyAdded) {
    yield put(setAddOnsProductInstanceIds([streamOnProductInstanceId]));
  }

  yield put(
    setUserEligibility({
      isEligible,
      hasStreamOnActive,
    })
  );
  yield put(setIsLoading(false));
}

function* getInvoiceHistory() {
  yield put(setInvoiceHistoryLoading(true));
  yield put(setIsLoading(true));

  const getInvoiceData: Query = yield call(graphqlQuery, {
    query: GET_INVOICE_HISTORY,
  });

  yield put(
    setInvoiceHistory(
      getInvoiceData.getInvoiceHistory?.currentMonthlyBill as any
    )
  );

  yield put(setIsLoading(false));
  yield put(setInvoiceHistoryLoading(false));
}

function* getAllDetails() {
  yield put(setOfferDetailsLoading(true));
  yield put(setUsagePageStatistics());

  const { offerDetails }: ReturnType<typeof selectStreamOnPromo> = yield select(
    selectStreamOnPromo
  );
  try {
    const allDetailsData: Query = yield call(graphqlQuery, {
      query: GET_ALL_DETAILS,
      fetchPolicy: !offerDetails.productTypeId ? 'no-cache' : undefined,
    });

    if (!allDetailsData.getOfferDetails) {
      yield put(setCurrentPage(Pages.error));
      yield put(setOfferDetailsLoading(false));
      return;
    }

    yield put(setOfferDetails(allDetailsData.getOfferDetails as any));
    yield put(setUserDetails(allDetailsData.getUserDetails as any));
    yield put(
      setShippingAddress(allDetailsData.getUserDetails?.shippingAddress as any)
    );
  } catch (e) {
    console.error(e);
    yield put(setCurrentPage(Pages.error));
    yield put(setOfferDetailsLoading(false));
  }
}

function* submitFullOrder({
  payload,
}: {
  type: string;
  payload: SubmitFullOrderInput;
}) {
  const {
    userInfo: { phone },
  }: ReturnType<typeof selectUserInfo> = yield select(selectUserInfo);

  yield put(setIsOrderSubmitting(true));
  yield put(setOrderFail(null));
  yield put(addOrderAttempts());

  try {
    const input = {
      address: {
        ...payload.address,
        labels: [ContactMethodLabels.Shipping],
      },
      isEditingNumber: payload.phoneNumber
        ? phone[phone.mobile as PhoneType]?.phoneNumber !== payload.phoneNumber
        : false,
      phoneNumber: payload?.phoneNumber?.replace(/[\s()-]/g, ''),
    };

    const submitOrderData: Mutation = yield call(graphqlMutation, {
      mutation: SUBMIT_FULL_ORDER,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });

    if (!submitOrderData?.submitFullOrder?.didOrderFail) {
      yield put(refetchUserInfo());
      yield put(setCurrentPage(Pages.orderSummary));
      yield put(setOrderAttempts(resetOrderAttempts));
      yield put(
        setUserEligibility({
          isEligible: true,
          hasStreamOnActive: true,
        })
      );
      yield put(setOrderSucceeded(true));
    } else {
      yield put(
        setOrderFail(
          submitOrderData?.submitFullOrder?.error?.failureReason ?? ''
        )
      );
      yield put(setCurrentPage(Pages.error));
    }
    yield put(setIsOrderSubmitting(false));
  } catch (e) {
    yield put(setIsOrderSubmitting(false));
    yield put(setOrderFail(JSON.stringify(e) ?? ''));
    yield put(setCurrentPage(Pages.error));
  }
}

function* deactivateStreamOn() {
  const config: ConfigState = yield select(selectConfig);
  if (config.env === 'PROD' || config.env === 'PREPROD')
    throw new Error('This can not be run in prod!');

  yield put(setDeactivateStreamOn({ loading: true }));

  const deactivateStreamOnData: Mutation = yield call(graphqlMutation, {
    mutation: DEACTIVATE_STREAM_ON,
    fetchPolicy: 'no-cache',
  });

  yield put(
    setDeactivateStreamOn({
      loading: false,
      successful: deactivateStreamOnData.deactivateStreamOn?.success ?? false,
    })
  );
}

function* scrubAddress({
  payload,
}: {
  type: string;
  payload: { address: Address | null };
}) {
  const address = payload?.address as Address;

  yield put(setIsScrubbingLoading(true));
  if (address.countryCode === '') address.countryCode = 'US';

  try {
    const data: Query = yield call(graphqlQuery, {
      query: GET_SCRUBBED_ADDRESS,
      variables: {
        address: { ...sanitizeAddressLines(address) },
      },
    });
    if (!data.getScrubbedAddress) {
      yield put(setScrubLocation(null));
    }
    const { shippingAddress }: ReturnType<typeof selectStreamOnPromo> =
      yield select(selectStreamOnPromo);

    const result = areAddressesEqual(data.getScrubbedAddress, shippingAddress);
    yield put(setScrubLocation(data.getScrubbedAddress as any));

    if (!result) {
      yield put(setConfirmModal(true));
      yield put(setIsScrubbingLoading(false));
    } else {
      yield put(setIsEdittingAddress(false));
      yield put(setShippingAddress(data.getScrubbedAddress as any));
      yield put(
        setEditAddress({
          addressLines: ['', ''],
          postalCode: '',
          countryCode: '',
          municipality: '',
          region: '',
        })
      );
    }

    yield put(setIsScrubbingLoading(false));
  } catch (e) {
    yield put(setIsScrubbingLoading(false));
    yield put(setCurrentPage(Pages.error));
  }
}

export function* watchStreamOnPromo() {
  yield spawn(function* () {
    yield all([
      takeLatest(fetchAddOns.type, checkUserEligibility),
      takeLatest(setOverviewPageStatistics.type, checkUserEligibility),
      takeLatest(setUsagePageStatistics.type, checkUserEligibility),
      takeLatest(setProfilePageStatistics.type, checkUserEligibility),
      takeLatest(getInvoiceHistoryAction.type, getInvoiceHistory),
      takeLatest(getAllDetailsAction.type, getAllDetails),
      takeLatest(submitFullOrderAction.type, submitFullOrder),
      takeLatest(scrubAddressAction.type, scrubAddress),
      takeLatest(deactivateStreamOnAction.type, deactivateStreamOn),
    ]);
  });
}
