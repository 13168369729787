import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Confirm } from '@mfe/shared/components';
import { ReactComponent as Illustration } from './desktopIllustration.svg';

export const AddonsGridEmptyState = () => {
  const history = useHistory();
  const { t } = useTranslation('ShopAddons');

  return (
    <Confirm
      title={t('emptyState.title')}
      description={t('emptyState.description')}
      buttons={[
        {
          children: t('emptyState.goBackLabel'),
          onClick: () => {
            history.goBack();
          },
        },
      ]}
    >
      <Illustration />
    </Confirm>
  );
};
