import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Surface, Txt, Button } from '@vst/beam';
import { OpenInNew } from '@vst/beam-icons/icons';

import { Storage, useScreenResolution } from '@mfe/shared/util';
import { selectStreamOnPromo } from '@mfe/to-be-migrated/redux/streamOnPromo';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { selectConfig } from '@mfe/shared/redux/config';
import useNavigate from '@mfe/services/navigation';

import { AddOnFooter } from './add-ons-footer';
import { NoAddons } from './no-add-ons';
import { AddOnsTypes } from './add-ons-types';
import styles from './styles.module.scss';

type Props = {
  handleManagePlan: () => void;
};

const useHasAddOns = () => {
  const { addOns } = useSelector(selectAddOns);
  const { hasStreamOnActive } = useSelector(selectStreamOnPromo);

  return (
    addOns.hasVoice ||
    addOns.hasEasyCare ||
    addOns.hasShield ||
    addOns.hasStaticIP ||
    addOns.hasOfficeHours ||
    hasStreamOnActive
  );
};

export const AddonUpsellCards = ({
  handleManagePlan,
}: Props): JSX.Element | null => {
  const { t } = useTranslation(['Overview', 'Global']);
  const { isLoading } = useSelector(selectStreamOnPromo);
  const { loading } = useSelector(selectAddOns);
  const { showAddonRedesign } = useSelector(selectConfig);
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;
  const hasAddOns = useHasAddOns();

  if (!showAddonRedesign) return null;

  if (isLoading || loading) return <Loading />;

  return (
    <Surface data-cy="addons-container" className={styles['container']}>
      <div className={styles['header']}>
        <Txt variant="bodyLargeBold">{t('Overview:addOns.title')}</Txt>
        {!isMobile && (
          <div className={styles['header-buttons']}>
            <MyAddonsButtons />
          </div>
        )}
      </div>

      {hasAddOns ? (
        <>
          <AddOnsTypes handleManagePlan={handleManagePlan} />
          {isMobile && (
            <div className={styles['header-buttons']}>
              <MyAddonsButtons />
            </div>
          )}
          <AddOnFooter />
        </>
      ) : (
        <NoAddons />
      )}
    </Surface>
  );
};

const MyAddonsButtons = () => {
  const hasAddOns = useHasAddOns();
  const { showAddonsShop } = useSelector(selectConfig);

  if (showAddonsShop) {
    return <MyAddonsButtonsNew />;
  }

  if (!hasAddOns) {
    return null;
  }

  return <MyAddonsButtonsOld />;
};

const MyAddonsButtonsOld = () => {
  const { t } = useTranslation(['Overview', 'Global']);
  const { goTo } = useNavigate();

  return (
    <Button
      variant="secondary"
      icon={OpenInNew}
      iconPos="right"
      onClick={(e) => {
        e.preventDefault();
        Storage.setItem('showInternetPlan', true);
        goTo('Profile');
      }}
    >
      {t('Overview:addOns.button')}
    </Button>
  );
};

const MyAddonsButtonsNew = () => {
  const { t } = useTranslation(['Overview', 'Global']);
  const { goTo } = useNavigate();
  const hasAddOns = useHasAddOns();

  return (
    <>
      {hasAddOns && (
        <Button
          variant="tertiary"
          onClick={(e) => {
            e.preventDefault();
            Storage.setItem('showInternetPlan', true);
            goTo('Profile');
          }}
        >
          {t('Overview:addOns.manageAddonsButtonLabel')}
        </Button>
      )}
      <Button
        variant="secondary"
        onClick={(e) => {
          e.preventDefault();
          goTo('ShopAddons');
        }}
      >
        {t('Overview:addOns.shopAddonsButtonLabel')}
      </Button>
    </>
  );
};

const Loading = () => {
  return (
    <Surface data-cy="loading" className={styles['loading-container']}>
      <div className={styles['loading-title']} />
      <div className={styles['loading-addon-section']}>
        <div className={styles['icon']} />
        <div className={styles['name']} />
        <div className={styles['desc']} />
      </div>
      <div className={styles['loading-footer']} />
    </Surface>
  );
};
